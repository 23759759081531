import classNames from "classnames";
import styles from "./Section.module.scss";

type SectionProps = {
  containerStyle?: string;
  children: React.ReactNode;
};

export const Section = ({ children, containerStyle }: SectionProps) => {
  return (
    <div className={classNames(styles.sectionContainer, containerStyle)}>
      <div className={styles.section}>{children}</div>
    </div>
  );
};
