import classNames from "classnames";
import TrackingService from "@/utils/trackingService";
import styles from "./CtaButton.module.scss";

type CtaButtonProps = {
  label: string;
  overloadStyle?: string;
  isFullWidth?: boolean;
  isMarineBg?: boolean;
  isDisabled?: boolean;
  isDesktopFilterPadding?: boolean;
  onClick?: () => void;
  dataCy?: string;
};

export const CtaButton = ({
  label,
  overloadStyle,
  isFullWidth = false,
  isMarineBg = false,
  isDesktopFilterPadding = false,
  isDisabled = false,
  onClick,
  dataCy,
}: CtaButtonProps) => (
  <div
    className={classNames(styles.ctaButtonContainer, {
      [styles.fullWidth]: isFullWidth,
      [styles.marineBg]: isMarineBg,
    })}
  >
    <button
      className={classNames(styles.ctaButton, overloadStyle, {
        [styles.isDisabled]: isDisabled,
        [styles.desktopFilterPadding]: isDesktopFilterPadding,
      })}
      onClick={() => {
        TrackingService.trackCtaClick(label);
        if (!isDisabled && onClick) {
          onClick();
        }
      }}
      data-cy={dataCy}
    >
      {label}
    </button>
  </div>
);
