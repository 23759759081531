import Image from "next/image";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak-fork/ssr";
import type { KeycloakTokenParsed } from "keycloak-js";
import { useIntl } from "react-intl";
import { Popover, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import router from "next/router";
import Link from "next/link";
import { routes } from "@/routes/routes";
import { LoginIcon } from "@/public/icons/LoginIcon";
import { RentACarColoredIcon } from "@/public/icons/RentACarColoredIcon";
import { CtaButton } from "@/components/common/ctaButton/CtaButton";
import { getBreakpoint } from "@/utils/mantine";
import { Section } from "@/components/common/section/Section";
import { formatName } from "@/utils/formatName";
import { MenuMobile } from "@/components/layout/headerLegacy/menuMobile/MenuMobile";
import serviceClient from "@/public/images/service-client.png";
import { MenuDesktop, MenuDesktopProps } from "./menuDesktop/MenuDesktop";
import styles from "./HeaderLegacy.module.scss";

export const HeaderLegacy = ({ menu }: MenuDesktopProps) => {
  const { keycloak, initialized } = useKeycloak();
  const parsedToken: KeycloakTokenParsed | undefined = keycloak?.tokenParsed;
  const [isOpen, setIsOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const theme = useMantineTheme();
  const isTabletOrDesktop = useMediaQuery(getBreakpoint(theme.breakpoints.sm));
  const intl = useIntl();

  useEffect(() => {
    if (initialized && keycloak) {
      if (keycloak.authenticated) {
        sessionStorage.setItem("keycloak_token", keycloak.token ?? "");
      }
      if (!keycloak.authenticated) {
        sessionStorage.removeItem("keycloak_token");
      }
    }
  }, [initialized, keycloak]);

  const onClickLogout = () => {
    keycloak?.logout().catch((err) => {
      console.log("Error during loging out ...", err);
    });
  };

  const onClickCta = () => {
    window.location.href = `${
      process.env.NEXT_PUBLIC_PRISME_URL ?? ""
    }/account/dashboard`;
  };

  const onClickLogin = () => {
    const intervalId = setInterval(() => {
      if (keycloak && initialized) {
        clearInterval(intervalId);
        void router.push(keycloak.createLoginUrl());
      }
    }, 100);
  };

  const closePopover = () => {
    if (isTabletOrDesktop) {
      setIsPopoverOpen(false);
    }
  };

  const redirectToHome = () => router.push(routes.home);

  return (
    <header>
      {isOpen && <div className={styles.invisibleSpace} />}
      <nav className={isOpen ? styles.fixedHeader : ""}>
        <Section containerStyle={styles.headerSectionColor}>
          <div className={styles.header}>
            <div className={styles.menu}>
              <MenuMobile menu={menu} isOpen={isOpen} setIsOpen={setIsOpen} />
              <MenuDesktop menu={menu} />
            </div>
            <div className={styles.racIcon}>
              <button
                className={styles.racIcon}
                type="button"
                onClick={redirectToHome}
              >
                <RentACarColoredIcon />
              </button>
            </div>
            <div className={styles.customerServiceIcon}>
              <Link
                href={
                  "https://rac-production-strapi-uploads.s3.eu-west-3.amazonaws.com/RENTACARELUSERVICECLIENTDELANNEE_2024_69671117d8.pdf"
                }
              >
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  className={styles.customerServiceLink}
                >
                  <Image
                    src={serviceClient}
                    alt="Service Client"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
            {initialized && keycloak?.authenticated ? (
              <Popover
                opened={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
                onMouseLeave={() => closePopover()}
                width={260}
                position="top"
                placement="center"
                spacing="xs"
                shadow="xs"
                withArrow
                radius="xs"
                trapFocus={false}
                classNames={{
                  wrapper: styles.popoverWrapper,
                  inner: styles.popoverInner,
                  arrow: styles.popoverArrow,
                  popover: styles.popover,
                  body: styles.popoverBody,
                }}
                target={
                  <>
                    <div
                      className={styles.loggedInDesktop}
                      onMouseEnter={() => setIsPopoverOpen(true)}
                    >
                      <LoginIcon color="white" />
                      {formatName(
                        parsedToken?.given_name as string,
                        parsedToken?.family_name as string
                      )}
                    </div>
                    <button
                      className={styles.loggedInMobile}
                      onClick={() => setIsPopoverOpen(true)}
                    >
                      <LoginIcon color="white" />
                    </button>
                  </>
                }
              >
                <div className={styles.popoverHeader}>
                  <div className={styles.popoverHeaderTitle}>
                    {intl.formatMessage({ id: "myAccount" })}
                  </div>
                  <div className={styles.popoverHeaderGreet}>
                    {intl.formatMessage(
                      { id: "popoverHeaderGreet" },
                      {
                        name: formatName(
                          parsedToken?.given_name as string,
                          parsedToken?.family_name as string
                        ),
                      }
                    )}
                  </div>
                </div>
                <div className={styles.popoverProfile}>
                  <CtaButton
                    label={intl.formatMessage({ id: "myAccount" })}
                    onClick={onClickCta}
                    isFullWidth
                  />
                </div>

                <a className={styles.popoverLogout} onClick={onClickLogout}>
                  {intl.formatMessage({ id: "logout" })}
                </a>
              </Popover>
            ) : (
              <>
                <button
                  className={styles.loginMobile}
                  onClick={() => onClickLogin()}
                >
                  <LoginIcon color="white" />
                </button>
                <button
                  className={styles.loginDesktop}
                  onClick={() => onClickLogin()}
                >
                  {intl.formatMessage({ id: "login" })}
                </button>
              </>
            )}
          </div>
        </Section>
      </nav>
    </header>
  );
};
